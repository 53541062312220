
class FormatHelper {

  // format date
  formatDate(date) {
    return date ? new Date(date).toLocaleString('fr-FR') : null;
  }

  // Return an array of balises
  balisesResponseFormat(data) {

    var balisesList = [];

    data.forEach(balise => {
      balisesList.push(this.baliseInfoFormat(balise));
    });

    return balisesList;
  }

  // Return an array of groups array including balises with the last latitude set
  groupsBalisesArrayFormat(data) {

    var groupsBalises = [];

    // Order data to get groups filled with balises
    data.forEach(balise => {

      // if balise has no last latitude set, then don't do nothing and don't add it
      if (balise.lastLatitude !== null) {
        var stringGroupId = null;
        var groupId = null;
        var groupName = null;

        // if balise has group, then us its data, if not then use groupless data
        if (balise.group != null) {
          stringGroupId = balise.group.id.toString();
          groupId = balise.group.id;
          groupName = balise.group.name;
        }
        else {
          stringGroupId = "0";
          groupId = 0;
          groupName = "Aucun groupe";
        }

        // if group array doesn't exist, then create it
        if (typeof groupsBalises[stringGroupId] === 'undefined') {
          groupsBalises[stringGroupId] =
          {
            groupId: groupId,
            groupName: groupName,
            balises: []
          }
        }

        // push the balise data to the group array
        groupsBalises[stringGroupId].balises.push(this.baliseInfoFormat(balise));
      }
    });
    return groupsBalises;
  }

  // Return an array balise info
  baliseInfoFormat(data) {

    var baliseInfo = [];

    let lastSeen;
    let lastLatitude;
    let lastLongitude;
    let lastBattery;
    let lastAddress;
    let lastSpeed;
    let lastPowerVoltage;
    let lastLevelGPS;
    let lastLevelGSM;
    let lastTemperature;

    // if balise last latitude is set, then the last position data exist
    if (data.lastLatitude !== null) {
      lastSeen = data.lastPositionDate;
      lastLatitude = data.lastLatitude;
      lastLongitude = data.lastLongitude;
      lastBattery = data.lastBattery;
      lastAddress = data.lastAddress;
      lastSpeed = data.lastSpeed;
      lastPowerVoltage = data.lastPowerVoltage;
      lastLevelGPS = data.lastLevelGPS;
      lastLevelGSM = data.lastLevelGSM;
      lastTemperature = data.lastTemperature;
    } else {
      lastSeen = "Aucune donnée";
      lastLatitude = "Aucune donnée";
      lastLongitude = "Aucune donnée";
      lastBattery = "Aucune donnée";
      lastAddress = "Aucune donnée";
      lastSpeed = "Aucune donnée";
      lastPowerVoltage = "Aucune donnée";
      lastTemperature = "Aucune donnée";
    }

    baliseInfo = {
      id: data.id,
      imei: data.baliseId,
      name: data.name,
      immat: data.registrationPlate,
      icon: data.icon,
      type: data.type,
      lastSeen: lastSeen,
      displayLastSeen: this.formatDate(data.lastPositionDate),
      lastLatitude: lastLatitude,
      lastLongitude: lastLongitude,
      lastBattery: lastBattery,
      lastPowerVoltage: lastPowerVoltage,
      lastAddress: lastAddress,
      lastSpeed: lastSpeed,
      lastLevelGPS: lastLevelGPS,
      lastLevelGSM: lastLevelGSM,
      lastTemperature: lastTemperature,
      updatedAt: data.lastPositionDate,
      createdAt: this.formatDate(data.createdAt),
      immatriculation: data.vehicleImmatriculation,
      remoteMonitoring: data.remoteMonitoring,
      free: data.free,
      allowedSMS: data.commercialPackage ? data.commercialPackage.allowedSMS : false,
      alertSMS: data.alertSMS,
      commercialPackageId: data.commercialPackageId,
      groupName: "Aucun groupe",
      groupId: null
    };

    // if balise has group, then us its data, if not then use groupless data
    if (data.group != null) {
      baliseInfo["groupName"] = data.group.name;
      baliseInfo["groupId"] = data.group.id;
    }

    return baliseInfo;
  }


  //Return Last X positions of balise
  balisePingsFormat(data) {
    var balisePings = []
    data.forEach(position => {

      var id = position.id;
      var latitude = position.latitude;
      var longitude = position.longitude;
      var battery = position.battery;
      var temperature = position.temperature;
      var address = position.address;
      var speed = position.speed;
      var direction = position.direction;
      var levelGPS = position.levelGPS;
      var levelGSM = position.levelGSM;
      var powerVoltage = position.powerVoltage;
      var createdAt = position.createdAt;
      var updatedAt = this.formatDate(position.updatedAt);
      var positionDate = this.formatDate(position.date);
      var baliseId = position.baliseId;

      balisePings.push(
        {
          id: id,
          latitude: latitude,
          longitude: longitude,
          battery: battery,
          temperature: temperature,
          address: address,
          speed: speed,
          direction: direction,
          levelGPS: levelGPS,
          levelGSM: levelGSM,
          powerVoltage: powerVoltage,
          date: positionDate,
          createdAt: createdAt,
          updatedAt: updatedAt,
          baliseId: baliseId,
          alert: position.alertId == null ? null : position.alert
        }
      )
    })
    return balisePings
  }


  // Return an array drive info
  driveInfoFormat(data) {

    const padWithZero = (number) => number.toString().padStart(2, '0')

    let duration = data.startDate && data.endDate ? Math.floor(((new Date(data.endDate) - new Date(data.startDate))/1000)/3600) + "h" + padWithZero(Math.floor((((new Date(data.endDate) - new Date(data.startDate))/1000) % 3600) / 60)) : ""

    let driveInfo = {
      id: data.id,
      startAddress: data.startAddress,
      endAddress: data.endAddress,
      startCity: data.startCity,
      endCity: data.endCity,
      displayStartDate: this.formatDate(data.startDate),
      displayEndDate: this.formatDate(data.endDate),
      duration: duration,
      startDate: data.startDate,
      endDate: data.endDate,
      distance: data.distance > 2000000 ? null : Math.round(data.distance / 1000 * 10) / 10,
      baliseId: data.baliseId,
      maxSpeed: data.maxSpeed,
      balise: data.balise ? this.baliseInfoFormat(data.balise) : null
    }

    return driveInfo;
  }


  //Return Last X drives of balise
  baliseDrivesFormat(data) {
    var baliseDrives = []
    data.forEach(driveData => {

      baliseDrives.push(
        this.driveInfoFormat(driveData)
      )
    })
    return baliseDrives
  }


  //Return Last X alerts of balise
  baliseAlertsFormat(data) {
    var alerts = []
    data.forEach(alert => {

      var id = alert.id;
      var latitude = alert.latitude;
      var longitude = alert.longitude;
      var battery = alert.battery;
      var address = alert.address;
      var speed = alert.speed;
      var direction = alert.direction;
      var powerVoltage = alert.powerVoltage;
      var createdAt = alert.createdAt;
      var updatedAt = this.formatDate(alert.updatedAt);
      var date = this.formatDate(alert.date);
      var alertName = alert.alertType.name;

      alerts.push(
        {
          id: id,
          latitude: latitude,
          longitude: longitude,
          battery: battery,
          address: address,
          speed: speed,
          direction: direction,
          powerVoltage: powerVoltage,
          date: date,
          createdAt: createdAt,
          updatedAt: updatedAt,
          alertName: alertName
        }
      )
    })
    return alerts
  }

  getPoplylineData(data) {
    var polylines = [];
    polylines['blue'] = [];
    polylines['orange'] = [];
    polylines['red'] = [];

    var currentSpeed = 50;

    var currentDataArray = [];

    data.forEach(position => {

      switch (currentSpeed) {
        case 50:
          if(position.speed <= 50) {
            currentDataArray.push([position.latitude, position.longitude]);
          } else {
            currentDataArray.push([position.latitude, position.longitude]);
            polylines['blue'].push(currentDataArray);
            currentDataArray = [];
            currentDataArray.push([position.latitude, position.longitude]);
            if(position.speed <= 90) {
              currentSpeed = 90;
            } else if(position.speed > 90) {
              currentSpeed = 130;
            }
          }
          break;
        case 90:
          if(position.speed <= 90 && position.speed > 50) {
            currentDataArray.push([position.latitude, position.longitude]);
          } else {
            currentDataArray.push([position.latitude, position.longitude]);
            polylines['orange'].push(currentDataArray);
            currentDataArray = [];
            currentDataArray.push([position.latitude, position.longitude]);
            if(position.speed <= 50) {
              currentSpeed = 50;
            } else if(position.speed > 90) {
              currentSpeed = 130;
            }
          }
          break;
        case 130:
          if(position.speed > 90) {
            currentDataArray.push([position.latitude, position.longitude]);
          } else {
            currentDataArray.push([position.latitude, position.longitude]);
            polylines['red'].push(currentDataArray);
            currentDataArray = [];
            currentDataArray.push([position.latitude, position.longitude]);
            if(position.speed <= 50) {
              currentSpeed = 50;
            } else if(position.speed <= 90) {
              currentSpeed = 90;
            }
          }
          break;
      }

    });

    // push the last array
    switch (currentSpeed) {
      case 50:
        polylines['blue'].push(currentDataArray);
        break;
      case 90:
        polylines['orange'].push(currentDataArray);
        break;
      case 130:
        polylines['red'].push(currentDataArray);
        break;
    }

    return polylines;
  }


  // Return an array coordinates for the given balises list
  getCoords(data) {

    var coordinates = [];

    data.forEach(balise => {

      // if balise has no last latitude set, then don't do nothing and don't add it
      if (balise.lastLatitude !== null) {

        // push the coordiates data to the array
        coordinates.push([
          balise.lastLatitude,
          balise.lastLongitude
        ]);
      }
    });
    return coordinates;
  }


  getChartTemperatureData(data) {

    var result = [
      ['Date', 'Température']
    ];

    data.forEach(pos => {
      result.push([this.formatDate(pos.date),pos.temperature]);
    });

    return result;

  }

  getChartSpeedData(data) {

    var result = [
      ['Date', 'Vitesse']
    ];

    data.forEach(pos => {
      result.push([this.formatDate(pos.date),pos.speed]);
    });

    return result;

  }

}

export default new FormatHelper();