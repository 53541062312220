<template>
  <DefaultHeader />
  <section class="container d-flex flex-column">
    <div class="row mb-2">
      <div class="col-12 col-md-6">
        <div class="card shadow p-3 mb-3 bg-white rounded">
          <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
            <a href="#!">
              <div class="mask" style="background-color: rgba(251, 251, 251, 0.15);"></div>
            </a>
          </div>
          <div class="card-body">
            <div v-if="!this.isOperator && !this.isViewer" class="d-flex justify-content-end">
              <div class="font-weight-bold mb-4 pointer-cursor px-1 text-primary" v-on:click="updatingData = true">
                <font-awesome-icon :icon="['fas', 'fa-pen-square']" size="xl" />
              </div>
            </div>

            <div v-if="!updatingData" class="d-flex flex-wrap justify-content-between">
                <div class="mx-2">
                  <VehicleNameDisplay :balise="baliseInfo"></VehicleNameDisplay>
                </div>
                <div class="mx-2">
                  <div>Groupe</div>
                  <p class="text-muted">{{ this.baliseInfo.groupName }}</p>
                </div>
            </div>


            <div v-else class="container">
              <div class="row d-flex justify-content-between my-2">
                <div class="col-6">
                  <div class="input-group">
                    <span class="input-group-text" id="input-name">Nom</span>
                    <input class="form-control" type="text" v-model="updatedName" aria-describedby="input-name" required>
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group mb-3">
                    <label class="input-group-text" for="inputGroupSelect01">Groupe</label>
                    <select class="form-select" id="inputGroupSelect01" v-model="updatedGroup" required>
                      <option :value="null">Aucun groupe</option>
                      <option v-for="group in groups" :key="group" :value=group[1].id>{{ group[0] }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center text-center mb-3">
                <div class="col-6">
                  <div class="input-group">
                    <span class="input-group-text" id="input-immat">Immatriculation</span>
                    <input class="form-control" type="text" v-model="updatedImmat" aria-describedby="input-immat" required>
                  </div>
                </div>
                <div class="col-6">
                  <p>Selectionnez l'icône :</p>
                  <img class="border rounded pointer-cursor" :src="baliseIcon.icon" :icons="icons"
                    v-on:click="changeBalise(this.$route.params.id); setBaliseToUpdate(this.$route.params.id)"
                    data-bs-toggle="modal" data-bs-target="#iconPicker">
                </div>
              </div>
              <hr class="my-4" />
              <div class="d-flex justify-content-center">
                <button class="btn btn-primary m-1" v-on:click="updateData(this.updatedName, this.updatedImmat, this.updatedGroup); updatingData = false">Mettre à jour</button>
                <button class="btn btn-light m-1" v-on:click="updatingData = false">Annuler</button>
              </div>
            </div>


            <hr class="my-4" />
            <div class="d-flex flex-wrap justify-content-between mb-3">
              <div class="mx-2">
                <h5 class="card-title fw-bold">Dernière position</h5>
              </div>
              <div class="mx-2">
                <span class="small text-muted">{{ this.baliseInfo.displayLastSeen }}</span>
              </div>
              <div class="mx-2">
                <span class="small text-muted">{{ $filters.timeAgo(this.baliseInfo.lastSeen) }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between">
              <div class="mx-2">
                <div>
                  <div>Adresse :</div>
                  <p class="text-muted">{{ this.baliseInfo.lastAddress }}</p>
                  <LatLongDisplay :latitude="this.baliseInfo.lastLatitude" :longitude="this.baliseInfo.lastLongitude"></LatLongDisplay>
                </div>
              </div>
              <div class="mx-2">
                <div>Réception GPS :</div>
                <GPSDisplay :gps="this.baliseInfo.lastLevelGPS" class="mb-2"></GPSDisplay>
                <div>Réception GSM :</div>
                <GSMDisplay :gsm="baliseInfo.lastLevelGSM" />
              </div>
            </div>

            <hr class="my-4" />
            <h5 class="card-title fw-bold">Balise</h5>
            <div class="container">
              <div class="row">
                <div class="col-9">
                  <div>IMEI</div>
                  <p class="text-muted">{{ this.baliseInfo.imei }}</p>
                </div>
                <div class="col-3 text-start">
                  <div>Batterie</div>
                  <BatteryDisplay :battery="this.baliseInfo.lastBattery" />
                </div>
              </div>
              <div class="row">
                <div class="col-9">
                  <div>Modèle</div>
                  <p class="text-muted">{{ this.baliseInfo.type }}</p>
                </div>
                <div class="col-3 text-start">
                  <div>Alimentation</div>
                  <div><AlimDisplay :alim="this.baliseInfo.lastPowerVoltage" /></div>
                </div>
              </div>
              <div class="row">
                <div class="col-9">
                  <div>Mise en service</div>
                  <p class="text-muted">{{ this.baliseInfo.createdAt }}</p>
                </div>
                <div class="col-3 text-start">
                  <div>Température</div>
                  <div><TemperatureDisplay :temperature="baliseInfo.lastTemperature"></TemperatureDisplay></div>
                </div>
              </div>
            </div>

            <hr class="my-4" />
            <div class="d-flex flex-wrap justify-content-between">
              <div class="mx-2">
                <p>Télésurveillance</p>
                <p v-if="this.baliseInfo.remoteMonitoring == 1" class="text-muted">Active</p>
                <p v-else class="text-muted">Désactivée</p>
              </div>
              <div class="mx-2">
                <p>Ref commerciale</p>
                <p v-if="this.baliseInfo.commercialPackageId == null" class="text-muted">Non renseignée</p>
                <p v-else class="text-muted">P{{ this.baliseInfo.commercialPackageId }}</p>
              </div>
              <div v-if="this.baliseInfo.free == 1" class="mx-2">
                <p class="text-muted">Balise de démonstration</p>
              </div>
            </div>
            
          </div>
        </div>

        <!-- Map car icon -->
        <div class="card shadow my-3 bg-white rounded">
          <div class="d-flex p-5 justify-content-center align-items-center map_background">
            <img :src="baliseIcon.icon">
          </div>
          <div class="card-footer bg-white py-3 text-center">
            <RouterLink :to="'/home/' + this.$route.params.id">Voir sur la carte</RouterLink>
          </div>
        </div>

      </div>


      <div class="col-12 col-md-6 d-flex flex-column">

        <ul class="nav nav-tabs bg-light rounded-top border border-1">
          <li class="nav-item">
            <div role="button" v-on:click="tab = 1" :class="{ active: tab == 1, 'text-decoration-underline': tab == 1 }" class="nav-link" aria-current="page" href="#">Trajets</div>
          </li>
          <li class="nav-item">
            <div role="button" v-on:click="tab = 2" :class="{ active: tab == 2, 'text-decoration-underline': tab == 2 }" class="nav-link" href="#">Alertes</div>
          </li>
          <li class="nav-item">
            <div role="button" v-on:click="tab = 3" :class="{ active: tab == 3, 'text-decoration-underline': tab == 3 }" class="nav-link" href="#">Positions</div>
          </li>
        </ul>

        <!-- Drives list -->
        <div v-if="this.tab == 1" class="card shadow bg-white">
          <div class="table-responsive p-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Départ</th>
                  <th scope="col"></th>
                  <th scope="col" class="text-end">Arrivée</th>
                </tr>
              </thead>
              <tbody>

                <tr v-for="(baliseDrive, index) in baliseDrives" :key="index">
                  <td colspan="3">

                    <router-link :to="'/drivepositions/' + baliseDrive.id" class="text-decoration-none">
                      <FullDriveDisplay :baliseDrive="baliseDrive" />
                    </router-link>
                    
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="card-footer p-3 text-center">
            <RouterLink :to="'/drives/' + this.$route.params.id">Voir toutes les données</RouterLink>
          </div>
        </div>

        <!-- Positions list -->
        <div v-if="tab == 3" class="card shadow bg-white ">
          <div class="table-responsive p-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Adresse</th>
                  <th scope="col">Alim</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(balisePing, index) in balisePings" :key="index">
                  <td v-if="balisePing && balisePing.date"><span class="small text-muted">{{ balisePing.date
                  }}</span></td>
                  <td v-else>Données inconnues</td>
                  <td v-if="balisePing && balisePing.address"><span class="small text-muted">{{ balisePing.address
                  }}</span></td>
                  <td v-else>Données inconnues</td>
                  <td v-if="balisePing && balisePing.powerVoltage"><span class="small text-muted">{{
                    balisePing.powerVoltage }}V</span></td>
                  <td v-else>Données inconnues</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer p-3 text-center">
            <RouterLink :to="'/positions/' + this.$route.params.id">Voir toutes les données</RouterLink>
          </div>
        </div>


        <!-- Alerts list -->
        <div v-if="tab == 2" class="card shadow bg-white">
          <div class="table-responsive p-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Alerte</th>
                  <th scope="col">Adresse</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(baliseAlert, index) in baliseAlerts" :key="index">
                  <td v-if="baliseAlert && baliseAlert.date"><span class="small text-muted">{{
                    baliseAlert.date
                  }}</span></td>
                  <td v-else>Données inconnues</td>
                  <td v-if="baliseAlert && baliseAlert.alertName"><span class="small text-muted">{{
                    baliseAlert.alertName
                  }}</span></td>
                  <td v-else>Données inconnues</td>
                  <td v-if="baliseAlert && baliseAlert.address"><span class="small text-muted">{{
                    baliseAlert.address }}</span></td>
                  <td v-else>Données inconnues</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer p-3 text-center">
            <RouterLink :to="'/alerts/' + this.$route.params.id">Voir toutes les données</RouterLink>
          </div>
        </div>

        <!-- Actions commands -->
         <!--
        <div class="card shadow my-3 bg-white">
          <div class="card-header h5 bg-white">
            Actions
          </div>

          <div class="card-body">
            <div class="row g-3">
              <div class="col-auto">
                <div class="form-check form-switch align-middle">
                  <input v-model="lock" class="form-check-input" type="checkbox" role="switch" id="visibility" checked>
                  <label v-if="this.lock" class="form-check-label text-success" for="visibility">Portes (actuellement : dévérrouillées)</label>
                  <label v-else class="form-check-label text-danger" for="visibility">Portes (actuellement : vérrouillées)</label>
                </div>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-auto">
                <div class="form-check form-switch align-middle">
                  <input v-model="start" class="form-check-input" type="checkbox" role="switch" id="visibility" checked>
                  <label v-if="this.start" class="form-check-label text-success" for="visibility">Démarreur (actuellement : débloqué)</label>
                  <label v-else class="form-check-label text-danger" for="visibility">Démarreur (actuellement : bloqué)</label>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer bg-white">
            Non souscrit :
            <div class="row g-3">
              <div class="col-auto">
                <div class="form-check form-switch align-middle">
                  <input v-model="remorquage" class="form-check-input" type="checkbox" role="switch" id="visibility">
                  <label class="form-check-label text-secondary" for="visibility">Détection remorquage</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      -->

      </div>

    </div>


    <!-- SMS alerts block -->
    <div v-if="this.baliseInfo.allowedSMS && (this.isAdmin || this.isClientAdmin)" class="row my-2">
      <section class="container d-flex flex-column">
        <div class="card shadow p-3 mb-3 bg-white rounded">
          <div class="card-header h5 bg-white">
            Alertes Textos
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-check form-switch">
                  <input v-model="baliseInfo.alertSMS" v-on:change="updateBaliseSMSStatus()" class="form-check-input"
                    type="checkbox" role="switch" id="allAlerts" checked>
                  <label class="form-check-label" for="allAlerts">Activer les alertes Textos pour ce véhicule<span
                      class="text-muted">*</span></label>
                </div>
              </div>
              <div class="mb-3 col-12 col-md-6 d-flex flex-column justify-content-between">
                <span>Textos restants pour le mois en cours : 57</span>
              </div>
            </div>

            <div v-if="baliseInfo.alertSMS" class="row">
              <hr>
              <div class="col-12 col-md-6">
                <div class="input-group my-2">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="alertSelect">Alerte</label>
                  </div>
                  <select v-model="selectedAlertType" v-on:change="refreshAlert()" class="form-select"
                    aria-label="Default select example" id="alertSelect">
                    <option v-for="(alert, index) in alertTypes" :key="index" :value="alert.id">{{ alert.name }}</option>
                  </select>
                </div>
                <div v-if="selectedAlertType" class="form-check form-switch my-4">
                  <input v-model="displayedAlert.active" v-on:change="updateAlertStatus()" class="form-check-input"
                    type="checkbox" role="switch" id="driveStartAlert">
                  <label v-if="displayedAlert.active" class="form-check-label" for="driveStartAlert">Activé</label>
                  <label v-else class="form-check-label" for="driveStartAlert">Désactivé</label>
                  <div>
                    <small v-if="selectedAlertType == 1" class="text-muted">
                      Pensez à désactiver cette alerte avant d'utiliser votre véhicule.
                    </small>
                  </div>
                </div>
              </div>
              <div v-if="displayedAlert.active"
                class="col-12 col-md-6 d-flex flex-column justify-content-between h-md-auto">
                <div v-if="!updatingPhoneNumber">
                  <div v-for="(number, index) in displayedAlert.phoneNumbers" :key="index"
                    class="d-flex justify-content-between input-group-text">
                    <span class="fw-bold">{{ number.name }}</span>
                    <span>{{ number.number }}</span>
                    <font-awesome-icon :icon="['fas', 'fa-square-minus']" v-on:click="removeAlertNumber(number.id)"
                      class="text-danger pointer-cursor" size="xl" />
                  </div>
                  <select v-model="selectedNumberToAdd" v-on:change="addAlertNumber()" class="form-select"
                    aria-label="Default select example">
                    <option value="undefined" selected>Ajouter un numéro de réception alerte Textos</option>
                    <option v-for="(number, index) in clientPhoneNumbers" :key="index" :value="number.id">
                      {{ number.name }} - {{ number.number }}
                    </option>
                  </select>
                  <div class="form-check d-flex justify-content-center mt-4">
                    <button v-on:click="this.updatingPhoneNumber = true;" data-mdb-button-init data-mdb-ripple-init
                      class="btn btn-primary btn-block mb-4">
                      Gérer les numéros
                    </button>
                  </div>
                </div>
                <div v-if="updatingPhoneNumber" class="mt-4">
                  <span class="fw-bold">Ajouter un numéro</span>
                  <div class="form-check d-flex justify-content-center mb-4">
                    <div class="mx-2">
                      <input v-model="newNumberName" type="text" class="form-control" placeholder="Nom du numéro">
                    </div>
                    <div class="mx-2">
                      <input v-model="newNumber" type="text" class="form-control" placeholder="Numéro">
                    </div>
                    <div>
                      <button class="btn btn-primary btn-block mx-2"
                        v-on:click="createPhoneNumber(); this.updatingPhoneNumber = false; this.newNumberName = ''; this.newNumber = ''">Ajouter</button>
                    </div>
                  </div>
                  <span class="fw-bold">Modifier un numéro</span>
                  <div class="form-check d-flex justify-content-center">
                    <select v-model="selectNumbersToUpdate" v-on:change="loadNumberToUpdate()" class="form-select"
                      aria-label="Default select example">
                      <option value="undefined" selected></option>
                      <option v-for="(number, index) in clientPhoneNumbers" :key="index" :value="number.id">
                        {{ number.name }} - {{ number.number }}
                      </option>
                    </select>
                  </div>
                  <div v-if="this.selectNumbersToUpdate != 'undefined'" class="form-check d-flex justify-content-center">
                    <div class="mx-2">
                      <input v-model="this.numberToUpdate.name" type="text" class="form-control"
                        placeholder="Nom du numéro">
                    </div>
                    <div class="mx-2">
                      <input v-model="this.numberToUpdate.number" type="text" class="form-control" placeholder="Numéro">
                    </div>
                    <div>
                      <button class="btn btn-primary btn-block mx-2"
                        v-on:click="updateNumber(); this.selectNumbersToUpdate = 'undefined'; this.updatingPhoneNumber = false;">Modifier</button>
                    </div>
                    <div>
                      <button class="btn btn-danger btn-block mx-2"
                        v-on:click="this.selectNumbersToUpdate = 'undefined'">Annuler</button>
                    </div>
                  </div>
                  <div class="mt-4">
                    <span class="fw-bold">Supprimer un numéro</span>
                    <div class="form-check d-flex justify-content-center">
                      <select v-model="selectedNumberToDelete" class="form-select" aria-label="Default select example">
                        <option value="undefined" selected></option>
                        <option v-for="(number, index) in clientPhoneNumbers" :key="index" :value="number.id"
                          v-on:click="this.updatedNumberName = number.name">
                          {{ number.name }} - {{ number.number }}
                        </option>
                      </select>
                      <div>
                        <button class="btn btn-danger btn-block mx-2"
                          v-on:click="deletePhoneNumber(this.selectedNumberToDelete); this.selectedNumberToDelete = 'undefined'; this.updatingPhoneNumber = false;">Supprimer</button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button class="btn btn-danger btn-block mt-4"
                      v-on:click="this.updatingPhoneNumber = false; this.newNumberName = ''; this.newNumber = ''; this.selectNumbersToUpdate = 'undefined';">Annuler</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer p-3 text-center bg-white">
            <small class="text-muted">* Attention : activer les alertes Textos peut occasionner des dépassements.
              Vérifiez votre offre auprès du service commercial.</small>
          </div>
        </div>
      </section>
    </div>


    <!-- CTA buttons bar -->
    <div class="row mt-2">
      <div class="col">
        <div class="card shadow mt-2 mt-md-2 p-3 bg-white rounded card-aside">
          <div class="row">
            <div class="col col-md-2 d-flex justify-content-center">
              <RouterLink :to="'/report/balise/' + this.$route.params.id">
                <button class="btn btn-primary">
                  Rapport PDF
                </button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <IconPickerModal :icons="icons" :baliseToUpdate="baliseToUpdate" />
  <DefaultFooter />
</template>
    
<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import BatteryDisplay from '../components/display/BatteryDisplay.vue'
import GSMDisplay from '../components/display/GSMDisplay.vue'
import GPSDisplay from '../components/display/GPSDisplay.vue'
import VehicleNameDisplay from '../components/display/VehicleNameDisplay.vue'
import LatLongDisplay from '../components/display/LatLongDisplay.vue'
import AlimDisplay from '../components/display/AlimDisplay.vue'
import TemperatureDisplay from '../components/display/TemperatureDisplay.vue'
import FullDriveDisplay from '../components/display/FullDriveDisplay.vue'
import BaliseService from '../services/balise.service'
import FormatHelper from '../helpers/format.helper.js'
import PositionService from '../services/position.service'
import DriveService from '../services/drive.service'
import AlertService from '../services/alert.service'
import AlertSMSService from '../services/alertSMS.service'
import PhoneNumberService from '../services/phoneNumber.service'
import IconPickerModal from '../components/balisesPage/IconPickerModal.vue'
import Group from '../services/group.service'
import UserHelper from '../helpers/user.helper.js'
import { ref } from 'vue'


export default {
  components: {
    DefaultHeader,
    DefaultFooter,
    BatteryDisplay,
    FullDriveDisplay,
    IconPickerModal,
    GSMDisplay,
    AlimDisplay,
    GPSDisplay,
    VehicleNameDisplay,
    LatLongDisplay,
    TemperatureDisplay
  },
  data() {
    return {
      baliseInfo: {},
      balisePings: [],
      baliseDrives: [],
      baliseAlerts: [],
      updatingData: false,
      icons: require.context('../assets/icons/', false, /^.*\.png$/),
      baliseToUpdate: null,
      groups: [],
      alertTypes: [],
      allAlerts: ref(true),
      displayedAlert: {},
      clientPhoneNumbers: [],
      selectedNumberToAdd: "undefined",
      updatingPhoneNumber: false,
      selectNumbersToUpdate: "undefined",
      numberToUpdate: {},
      tab: 1,
      lock: false,
      start: false
    };
  },
  computed: {
    baliseIcon: function () {
      return {
        icon: this.baliseInfo.icon && require(`../assets/icons/${this.baliseInfo.icon}`)
      }
    },
    isViewer() {
      return UserHelper.isViewer(this.$store.state.auth.user);
    },
    isClientAdmin() {
      return UserHelper.isClientAdmin(this.$store.state.auth.user);
    },
    isAdmin() {
      return UserHelper.isAdmin(this.$store.state.auth.user);
    },
    isOperator() {
      return UserHelper.isOperator(this.$store.state.auth.user);
    }
  },
  mounted() {
    this.refreshData();
    this.baliseLastsPings();
    this.baliseLastsDrives();
    this.baliseLastsAlerts();
    this.getAllGroups();
    this.getAlertTypes();
    this.getClientNumbers();
  },
  methods: {

    /************************************************/
    /**** START DISPLAYED INFO LOADING FUNCTIONS ****/
    /************************************************/

    // Get the balise data
    refreshData() {
      BaliseService.getBaliseInfo(this.$route.params.id)
        .then(response => {
          this.baliseInfo = FormatHelper.baliseInfoFormat(response.data);
          this.updatedName = this.baliseInfo.name
          this.updatedImmat = this.baliseInfo.immat
          this.updatedGroup = this.baliseInfo.groupId
        })
        .catch(e => {
          console.log(e);
        });
    },
    // Get the 3 last positions to be displayed in the positions area
    baliseLastsPings() {
      PositionService.getBalisePositions(this.$route.params.id, 0, 3)
        .then(response => {
          this.balisePings = FormatHelper.balisePingsFormat(response.data.rows)
        })
    },
    // Get the 3 last drives to be displayed in the drives area
    baliseLastsDrives() {
      DriveService.getBaliseDrives(this.$route.params.id, 0, 3)
        .then(response => {
          this.baliseDrives = FormatHelper.baliseDrivesFormat(response.data.rows)
        })
    },
    // Get the 3 last alerts to be displayed in the alerts area
    baliseLastsAlerts() {
      AlertService.getBaliseAlerts(this.$route.params.id, 0, 3)
        .then(response => {
          this.baliseAlerts = FormatHelper.baliseAlertsFormat(response.data.rows)
        })
    },

    /**********************************************/
    /**** END DISPLAYED INFO LOADING FUNCTIONS ****/
    /**********************************************/

    /************************************/
    /**** START ALERTS SMS FUNCTIONS ****/
    /************************************/

    // Get the alertTypes available for SMS config
    getAlertTypes() {
      AlertSMSService.getAlertTypes()
        .then(response => {
          this.alertTypes = response.data
        })
    },
    // Get the available phoneNumbers for the current client account
    getClientNumbers() {
      PhoneNumberService.getClientNumbers()
        .then(response => {
          this.clientPhoneNumbers = response.data
        })
    },
    // Refresh the displayed alert
    refreshAlert() {
      AlertSMSService.getBaliseAlertRule(this.baliseInfo.id, this.selectedAlertType)
        .then(response => {
          this.displayedAlert = response.data
          // If received alert is not set, init the displayed.active var
          if (typeof this.displayedAlert.active === 'undefined')
            this.displayedAlert = { active: false }
        })
    },
    // Add the selected number to the current displayed rule
    addAlertNumber() {
      AlertSMSService.addRuleNumber(this.displayedAlert.id, this.selectedNumberToAdd)
        .then(() => {
          this.selectedNumberToAdd = "undefined";
          this.refreshAlert();
        })
    },
    // Remove the selected number from the current displayed rule
    removeAlertNumber(numberId) {
      AlertSMSService.removeRuleNumber(this.displayedAlert.id, numberId)
        .then(() => {
          this.refreshAlert();
        })
    },
    // Create a phone number for the current client
    createPhoneNumber() {
      PhoneNumberService.addClientNumbers(this.newNumberName, this.newNumber)
        .then(() => {
          this.getClientNumbers();
        })
    },
    // Load the number to be updated
    loadNumberToUpdate() {
      PhoneNumberService.getNumberInfo(this.selectNumbersToUpdate)
        .then(response => {
          this.numberToUpdate = response.data;
        })
    },
    // Update the number with current fields data
    updateNumber() {
      PhoneNumberService.updateNumber(this.selectNumbersToUpdate, { name: this.numberToUpdate.name, number: this.numberToUpdate.number })
        .then(() => {
          this.getClientNumbers();
        })
    },
    // Delete the selected phone number
    deletePhoneNumber(numberId) {
      PhoneNumberService.deleteNumber(numberId)
        .then(() => {
          this.getClientNumbers();
          this.refreshAlert();
        })
    },
    // Activate / unactivate the overall SMS alert for the current balise
    updateBaliseSMSStatus() {
      BaliseService.updateData(this.baliseInfo.id, { alertSMS: this.baliseInfo.alertSMS });
    },
    // Activate / unactivate the SMS alert for the current balise + alertType
    updateAlertStatus() {
      AlertSMSService.updateRule(this.baliseInfo.id, this.selectedAlertType, typeof this.displayedAlert.active !== 'undefined' ? this.displayedAlert.active : true)
        .then(response => {
          if (response.status == 200)
            this.refreshAlert();
        })
    },

    /**********************************/
    /**** END ALERTS SMS FUNCTIONS ****/
    /**********************************/

    setBaliseToUpdate(param) {
      this.baliseToUpdate = param;
    },
    changeBalise(bId) {
      this.$emit("param", bId);
    },
    updateData(name, immat, groupId) {

      BaliseService.updateData(this.baliseInfo.id, { name: name, registrationPlate: immat, groupId: groupId })
        .then(() => {
          this.refreshData()
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAllGroups() {
      Group.getClientGroups().then(groups => {
        for (let group in groups.data) {
          this.groups.push([groups.data[group].name, groups.data[group]])
        }
      })
    }

  }
}
</script>

<style>
.map_background {
  background-image: url('../assets/Img_carte.png');
}

.pointer-cursor:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .h-md-auto {
    height: auto !important;
  }
}
</style>
