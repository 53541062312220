class UserHelper {

    currentUser = JSON.parse(localStorage.getItem('user'));

    // Test user is admin or not
    isAdmin(user = this.currentUser) {
        if(user && user["role"] == "superadmin")
        {
            return true;
        }
        return false;
    }

    // Test user is super operator or not
    isOperator(user = this.currentUser) {
        if(user && user["role"] == "superoperator")
        {
            return true;
        }
        return false;
    }

    // Test user is a client viewer or not
    isViewer(user = this.currentUser) {
        if(user && user["role"] == "clientviewer")
        {
            return true;
        }
        return false;
    }

    // Test user is a client admin or not
    isClientAdmin(user = this.currentUser) {
        if(user && user["role"] == "clientadmin")
        {
            return true;
        }
        return false;
    }

    // Get the user client ID or the chosen client ID to display if current user is admin
    getClientId(user = this.currentUser) {

        if(!user)
        {
            user = JSON.parse(localStorage.getItem('user'));
        }

        if(this.isAdmin() || this.isOperator())
        {
            let clientId = JSON.parse(localStorage.getItem('clientIdToWatch'));
            return Number.isInteger(clientId) ? clientId : 1;
        }
        return user["clientId"];
    }

}

export default new UserHelper();