<template>
    <DefaultHeader />
    <div class="container-fluid">
        <div class="row d-flex justify-content-center">
            <div class="col-11 mb-3 mb-lg-5">
                <div class="card table-nowrap table-card">
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <thead class="small text-uppercase bg-body text-muted">
                                <tr class="align-middle">
                                    <td>
                                    <div class="form-group">
                                        <input v-model="email" type="email" class="form-control" aria-describedby="emailHelp" placeholder="Adresse e-mail">
                                    </div>
                                    </td>
                                    <td>
                                    <div class="form-group">
                                        <input v-model="password" type="password" class="form-control" placeholder="Mot de passe">
                                    </div>
                                    </td>
                                    <td>
                                    <div class="form-group">
                                        <select v-model="role" class="form-select">
                                            <option selected value="clientviewer">Visiteur</option>
                                            <option value="clientoperator">Utilisateur</option>
                                            <option value="clientadmin">Administrateur</option>
                                        </select>
                                    </div>
                                    </td>
                                    <td class="text-end">
                                        <button class="btn btn-primary btn-block" @click="createUser()"><font-awesome-icon :icon="['fas', 'circle-plus']" /> Ajouter utilisateur</button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Utilisateur</th>
                                    <th>Dernière connexion</th>
                                    <th>Niveau d'accès</th>
                                    <th>Groupes autorisés</th>
                                    <th class="text-end"></th>
                                </tr>
                                <TableRow 
                                    v-for="user in users"
                                    :key="user.id"
                                    :user="user"
                                    :groups="this.groups" />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DefaultFooter />
</template>

<script>
import DefaultHeader from '../components/DefaultHeader.vue'
import DefaultFooter from '../components/DefaultFooter.vue'
import TableRow from '../components/usersPage/TableRow.vue'
import UserService from '../services/user.service.js'
import GroupService from '../services/group.service.js'
import AuthService from '../services/auth.service.js'


export default {
    components: {
        DefaultHeader,
        DefaultFooter,
        TableRow
    },
    data() {
        return {
            users: null,
            groups: null
        };
    },
    mounted() {
        this.refreshData();
        this.getAllGroups();
        this.email = "";
        this.password = "";
        this.role = "";
    },
    methods: {
        refreshData() {
            UserService.getClientUsers()
                .then(response => {
                    this.users = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        createUser() {
            AuthService.createUser(this.email, this.password, this.role)
            .then(() => {
                this.refreshData();
            })
            .catch(e => {
                console.log(e);
            });
            this.email = "";
            this.password = "";
            this.role = "";
        },
        getAllGroups() {
            GroupService.getClientGroups()
            .then(groupList => {
                this.groups = groupList.data;
            })                
            .catch(e => {
                console.log(e);
            });
        }
    }
}
</script>