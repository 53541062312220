<template>
    <div class="row d-flex justify-content-center">
        <div class="col-12 d-flex flex-column justify-content-between">
            <div class="card shadow mt-2 my-md-0 p-3 bg-white rounded">
                <div class="d-flex justify-content-center">
                    <nav v-if="totalPages>0" aria-label="...">
                        <Pagination 
                            :totalPages="totalPages" 
                            :perPage="perPage" 
                            :currentPage="currentPage" 
                            @pagechanged="onPageChange"
                        />
                    </nav>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th v-if="this.displayCounter" scope="col">Num</th>
                                <th scope="col">Date</th>
                                <th scope="col" class="text-center">Réseau</th>
                                <th scope="col">Vitesse</th>
                                <th scope="col" class="text-center">Direction</th>
                                <th scope="col">Position</th>
                                <th scope="col" class="text-center">Alim</th>
                                <th scope="col" class="text-center">Batterie</th>
                                <th scope="col" class="text-center">Température</th>
                                <th scope="col" class="text-center">Alerte</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(balisePing, index) in balisePings" :key="index">
                                <td v-if="this.displayCounter">{{ index + 1 + this.perPage * (this.currentPage-1) }}</td>
                                <td v-if="balisePing && balisePing.date">{{ balisePing.date }}</td>
                                <td v-else>N.C.</td>
                                <td class="text-center">
                                    <div class="d-inline-flex align-items-center"><span class="small me-1">GSM:</span><GSMDisplay :gsm="balisePing.levelGSM" ></GSMDisplay></div>
                                    <br><div class="d-inline-flex align-items-center mt-2"><span class="small me-1">GPS:</span><GPSDisplay :gps="balisePing.levelGPS" ></GPSDisplay></div>
                                </td>
                                <td v-if="balisePing" class="text-center"><small>{{ balisePing.speed }} Km/h</small></td>
                                <td v-if="balisePing && balisePing.direction" class="text-center"><span class="d-inline">{{ balisePing.direction }}°</span> <PositionDirection :position="balisePing" class="d-inline"></PositionDirection></td>
                                <td v-else class="text-center">N.C.</td>
                                <td v-if="balisePing && balisePing.latitude">
                                    <div><span>{{ balisePing.address }}</span></div>
                                    <div><LatLongDisplay :latitude="balisePing.latitude" :longitude="balisePing.longitude"></LatLongDisplay></div>
                                </td>
                                <td v-else>N.C.</td>
                                <td v-if="balisePing && balisePing.powerVoltage" class="text-center"><AlimDisplay :alim="balisePing.powerVoltage"></AlimDisplay></td>
                                <td v-else class="text-center">N.C.</td>
                                <td v-if="balisePing && balisePing.battery" class="text-center">
                                    <BatteryDisplay :battery="balisePing.battery" />
                                </td>
                                <td v-else class="text-center">N.C.</td>
                                <td v-if="balisePing && balisePing.temperature" class="text-center">
                                    <TemperatureDisplay :temperature="balisePing.temperature"></TemperatureDisplay>
                                </td>
                                <td v-else class="text-center">N.C.</td>
                                <td v-if="balisePing && balisePing.alert">
                                    <AlertDisplay :alert="balisePing.alert"></AlertDisplay>
                                </td>
                                <td v-else>N.C.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="totalPages>0" class="d-flex justify-content-center my-2">
                    <div class="row col-12 col-md-6">
                        <div class="d-flex justify-content-start">
                            <div class="col-8"><label for="resPerPage">Résultats par page :</label></div>
                            <div class="col-3"><select name="resPerPage" id="resPerPage" class="form-select"
                                    v-model="this.perPage"
                                    @change="setPerPage($event); this.currentPage = 1; onPageChange(this.currentPage)">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                </select></div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <nav v-if="totalPages>0" aria-label="...">
                        <Pagination 
                            :totalPages="totalPages" 
                            :perPage="perPage" 
                            :currentPage="currentPage" 
                            @pagechanged="onPageChange"
                        />
                    </nav>
                    <div v-if="pingsCount<1">
                        <p>Aucunes données disponibles</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PositionService from '../services/position.service'
import FormatHelper from '../helpers/format.helper.js'
import BatteryDisplay from '../components/display/BatteryDisplay.vue'
import PositionDirection from "./display/PositionDirection.vue";
import GSMDisplay from './display/GSMDisplay.vue'
import GPSDisplay from './display/GPSDisplay.vue'
import LatLongDisplay from './display/LatLongDisplay.vue'
import AlimDisplay from './display/AlimDisplay.vue'
import TemperatureDisplay from './display/TemperatureDisplay.vue'
import AlertDisplay from './display/AlertDisplay.vue'
import Pagination from '../components/PaginationComponent.vue'


export default {
    props: ['baliseId','givenStartDate','givenEndDate','order','displayCounter'],
    components: {
        BatteryDisplay,
        Pagination,
        GSMDisplay,
        AlimDisplay,
        PositionDirection,
        AlertDisplay,
        GPSDisplay,
        LatLongDisplay,
        TemperatureDisplay
    },
    data() {
        return {
            balisePings: [],
            pingsCount: null,
            pages: null,
            currentPage: 1,
            startResults: 0,
            perPage: 15,
            isEnd: false,
            displayEnd : false,
            startDate: null,
            endDate: null,
            totalPages: 0
        }
    },
    mounted() {
        if(this.givenStartDate) {
            console.log(this.givenStartDate)
            this.startDate=this.givenStartDate
        }
        if(this.givenEndDate) {
            this.endDate=this.givenEndDate
        }
        this.balisePagesPings()
    },
    methods: {
        changeFilters(startDate, endDate) {
            this.startDate = this.convertToUTC(startDate);
            console.log("this.stardDate = "+this.startDate)
            this.endDate = this.convertToUTC(endDate, true);
            console.log("this.endDate = "+this.endDate)
            this.balisePagesPings();
            this.currentPage = 1;
            this.onPageChange(this.currentPage)
        },
        balisePagesPings() {
            this.balisePings = []
            PositionService.getBalisePositions(this.baliseId, this.startResults, this.perPage, this.startDate, this.endDate, this.order)
                .then(response => {
                    this.balisePings = FormatHelper.balisePingsFormat(response.data.rows)
                    this.pingsCount = response.data.count
                    this.totalPages = Math.ceil(this.pingsCount/this.perPage)
                    console.log(this.pingsCount)
                })
        },
        onPageChange(page){
            if (page > 1) {
                this.startResults = (page-1) * this.perPage
            }
            else {
                this.startResults = 0
            }
            this.balisePings = []
            PositionService.getBalisePositions(this.baliseId, this.startResults, this.perPage, this.startDate, this.endDate, this.order)
                .then(response => {
                    this.balisePings = FormatHelper.balisePingsFormat(response.data.rows)
                })
            this.totalPages = Math.ceil(this.pingsCount/this.perPage)
            this.currentPage = page
        },
        setPerPage(event) {
            this.perPage = event.target.value
            this.totalPages = Math.ceil(this.pingsCount/this.perPage)
        },
        convertToUTC(dateStr, endOfDay = false) {
            const date = new Date(dateStr);

            if (endOfDay) {
                date.setHours(23, 59, 59, 999); // Réglez l'heure à la fin de la journée locale
            } else {
                date.setHours(0, 0, 0, 0); // Réglez l'heure au début de la journée locale
            }

            const localDate = new Date(date.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));
            return localDate.toISOString().slice(0, 19); // Convertir en UTC et formater en 'YYYY-MM-DDTHH:MM:SS'
        }

    }
}
</script>