<template>
    <div class="flex-grow-1">
        <!-- :options="{attributionControl: false}" => Remove the auto leaflet link in bottom right of the map -->
        <l-map v-if="this.positions"
            :zoom="zoom" 
            :center="center"
            :bounds="bounds"
            :options="{ attributionControl: false }">
            <l-tile-layer v-for="tileProvider in tileProviders" 
                :key="tileProvider.name" 
                :name="tileProvider.name"
                :visible="tileProvider.visible" 
                :url="tileProvider.url" layer-type="base" />
            <l-control-layers position="bottomright"></l-control-layers>
            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
            <l-control position="bottomleft">
                <div class="row mb-2 mx-2 px-1">
                    <div class="col-auto badge blue-square text-white text-decoration-none small text-nowrap">
                        0-50 km/h
                    </div>
                </div>
                <div class="row my-2 mx-2 px-1">
                    <div class="badge orange-square text-white text-decoration-none small text-nowrap">
                        50-90 km/h
                    </div>
                </div>
                <div class="row mt-2 mx-2 px-1">
                    <div class="badge red-square text-white text-decoration-none small text-nowrap">
                        90+ km/h
                    </div>
                </div>
            </l-control>
            <div v-for="marker in this.positions"
            :key="marker.id">
                <HistoryMarker v-if="marker.alert != null"
                    :marker="marker"
                    :iconSize="[16,16]"
                    :iconAnchor="[8,8]" />
            </div>
            <div v-if="this.polylineData['blue']">
                <l-polyline v-for="(polyline, index) in this.polylineData['blue']"
                    :key="index"
                    :lat-lngs="polyline"
                    :color="'#0d6efd'"
                    :opacity="0.65"
                    :weight="6">
                </l-polyline>
            </div>
            <div v-if="this.polylineData['orange']">
                <l-polyline v-for="(polyline, index) in this.polylineData['orange']"
                    :key="index"
                    :lat-lngs="polyline"
                    :color="'#ed8702'"
                    :opacity="0.65"
                    :weight="6">
                </l-polyline>
            </div>
            <div v-if="this.polylineData['orange']">
                <l-polyline v-for="(polyline, index) in this.polylineData['red']"
                    :key="index"
                    :lat-lngs="polyline"
                    :color="'#ed0202'"
                    :opacity="0.65"
                    :weight="6">
                </l-polyline>
            </div>
            <HistoryControlPanel :balise="this.drive.balise" :drive="this.drive"/>
        </l-map>
    </div>
</template>
  
<script>
import "leaflet/dist/leaflet.css";
import { LMap, LControl, LTileLayer, LControlLayers, LPolyline, LControlScale } from "@vue-leaflet/vue-leaflet";
import HistoryMarker from "./HistoryMarker.vue";
import HistoryControlPanel from "./HistoryControlPanel.vue";
import PositionService from '../../services/position.service.js'
import DriveService from '../../services/drive.service.js'
import FormatHelper from '../../helpers/format.helper.js'

export default {
    components: {
        LMap,
        LTileLayer,
        LControlLayers,
        LPolyline,
        HistoryMarker,
        HistoryControlPanel,
        LControlScale,
        LControl
    },
    watch:{
        $route: function() {
            this.getDriveInfos();
        }
    },
    data() {
        return {
            url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
            zoom: 13,
            center: [48.9571487, 2.1941876],
            bounds: [],
            maxBounds: [],
            timer: '',
            drive: {},
            positions: {},
            startMarker: null,
            endMarker: null,
            polylineData: {},
            tileProviders: [
                {
                    name: 'Classique',
                    visible: true,
                    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
                },
                {
                    name: 'Satellite',
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                },
            ],
        };
    },
    mounted() {
        this.getDriveInfos();
    },
    methods: {
        getDriveInfos() {
            DriveService.getDriveInfo(this.$route.params.driveid)
                .then(response => {
                    this.drive = FormatHelper.driveInfoFormat(response.data);
                    this.getPositions();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        getPositions() {
            PositionService.getBalisePositions(this.drive.baliseId, 0, 500, this.drive.startDate, this.drive.endDate)
                .then(response => {
                    this.positions = FormatHelper.balisePingsFormat(response.data.rows.filter(Array));
                    this.startMarker = this.positions[response.data.rows.length-1];
                    this.endMarker = this.positions[0];
                    this.bounds = [[this.startMarker.latitude,this.startMarker.longitude],[this.endMarker.latitude,this.endMarker.longitude]];
                    this.polylineData = FormatHelper.getPoplylineData(this.positions);
                })
        }
    }
}
</script>

<style>
.blue-square {
    background-color: #0d6efd;
    opacity: 0.65;
}

.orange-square {
    background-color: #ed8702;
    opacity: 0.65;
}

.red-square {
    background-color: #ed0202;
    opacity: 0.65;
}
</style>