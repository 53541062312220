<template>
  <div class="flex-grow-1">
    <!-- :options="{attributionControl: false}" => Remove the auto leaflet link in bottom right of the map -->
    <l-map
      :zoom="zoom" 
      :center="center" 
      :options="{ attributionControl: false }" 
      :bounds="bounds"
      :max-bounds="maxBounds"
      :maxZoom="maxZoom"
      :minZoom="minZoom">
      <l-tile-layer
      v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :name="tileProvider.name"
      :visible="tileProvider.visible"
      :url="tileProvider.url"
      :maxZoom="maxZoom"
      layer-type="base"/>
      <l-control-layers position="bottomright"></l-control-layers>
      <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
      <l-layer-group
          v-for="bGroup in groupsBalises" 
          :key="bGroup.groupId" layer-type="overlay" :name="bGroup.groupName" :visible="true">
              <BaliseMarker
              v-for="balise in bGroup.balises" 
              :key="balise.id"
              :balise="balise"
              :followMode="this.followedBalise === null ? false : true"
              @bId="followMarker"
              @click="$refs.controlPanel.open(balise)"
              />
      </l-layer-group>
      <MapControlPanel :followMode="this.followedBalise === null ? false : true" @bId="followMarker" ref="controlPanel"/>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LControlLayers, LLayerGroup, LControlScale } from "@vue-leaflet/vue-leaflet";
import BaliseMarker from "./BaliseMarker.vue";
import MapControlPanel from "./MapControlPanel.vue";
import BaliseService from '../../services/balise.service.js'
import FormatHelper from '../../helpers/format.helper.js'

export default {
  components: {
    LMap,
    LTileLayer,
    BaliseMarker,
    LControlLayers,
    LLayerGroup,
    LControlScale,
    MapControlPanel
  },
  data() {
    return {
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
      zoom: 15,
      center: [48.9571487, 2.1941876],
      followedBalise: null,
      bounds: [],
      maxBounds: [],
      timer: '',
      maxZoom: 19,
      minZoom: 3,
      groupsBalises: null,
      tileProviders: [
        /*{
          name: 'Test1',
          visible: true,
          url: 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png',
        },
        {
          name: 'Test2',
          visible: false,
          url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
        },*/
        {
          name: 'Classique',
          visible: true,
          url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
        },
        {
          name: 'Satellite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        },
      ]
    };
  },
  created() {
    if(this.$route.name != 'MapFollowBalise') {
      this.refreshMap();
    } else {
      this.followMarker(this.$route.params.bid);
    }
    this.timer = setInterval(this.refreshMap, 5000);
  },
  beforeUnmount () {
    this.cancelAutoUpdate();
  },
  updated() {
    console.log('its updated');
  },
  methods: {
    followMarker(bId) {
      this.followedBalise = bId;
      this.refreshMap();
      this.bounds = null;
      this.zoom = 16;
      this.minZoom = 7;
      this.maxZoom = 18;
    },
    refreshMap() {
      if(this.followedBalise === null) {
        BaliseService.getGroupBalisesLastPositions()
        .then(response => {
            this.groupsBalises = FormatHelper.groupsBalisesArrayFormat(response.data).filter(Array);
            // If there is only 1 vehicle, specific display
            if(response.data.length < 2) {
              this.center = [response.data[0].lastLatitude, response.data[0].lastLongitude];
            }
            else {
              this.bounds = FormatHelper.getCoords(response.data);
            }
        })
        .catch(e => {
            console.log(e);
        });
      }
      else {
        BaliseService.getBaliseInfo(this.followedBalise)
        .then(response => {
          this.groupsBalises = FormatHelper.groupsBalisesArrayFormat([response.data]).filter(Array);
          this.maxBounds = [[response.data.lastLatitude, response.data.lastLongitude], [response.data.lastLatitude, response.data.lastLongitude]];
        })
        .catch(e => {
          console.log(e);
        });
      }
    },
    cancelAutoUpdate () {
      clearInterval(this.timer);
    }
  }
}
</script>